<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-button type="primary" @click="showAdd" v-auth="this.per.PERMISSION_ADD"> 添加 </el-button>
      </el-col>
    </el-row>

    <div>
      <el-table
        ref="table"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :data="dataSource"
        :expand-row-keys="expandKeys"
        @expand-change="expandChange"
        @row-click="cellClick"
        row-class-name="table-row"
        row-key="id"
      >
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="url" prop="url"></el-table-column>
        <el-table-column label="操作" prop="operation">
          <template #default="scope">
            <span
              class="option option-primary"
              @click.stop="showEdit(scope.row)"
               v-auth="this.per.PERMISSION_UPDATA"
              >编辑</span
            >
            <span
              class="option option-danger"
               v-auth="this.per.PERMISSION_DEL"
              @click="showDel(scope.row)"
              
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditResourceModal
      :ref="MODAL_KEY.EDIT_RESOURCE_MODAL"
      :modalData="formValidateData"
      :modalType="modalType"
      :resources="resources"
      @submit="operationSource"
    ></EditResourceModal>
  </div>
</template>

<script>
import {
  addPermission,
  updatePermission,
  delPermission,
  fetchPermissionTree,
} from "@/api/permission";
import EditResourceModal from "./component/EditResourceModal";

export default {
  data() {
    return {
      MODAL_KEY: {
        EDIT_RESOURCE_MODAL: "EDIT_RESOURCE_MODAL",
      },
      resources: [],
      treeData: [],
      expandKeys: [],
      modalType: "",
      query: {
        adminId: -1,
      },
      pages: {
        pageIndex: 1,
        size: 20,
      },
      total: 0,
      formValidateData: {},
      dataSource: [],
    };
  },
  components: {
    EditResourceModal: EditResourceModal,
  },

  methods: {
    expandChange(e, isExpand) {
      console.log('changes');
      if (isExpand) {
        this.expandKeys.push(e.id);
      } else {
        this.expandKeys.splice(this.expandKeys.indexOf(e.id), 1);
      }
    },
    cellClick(e) {
      this.$refs.table.toggleRowExpansion(e);
    },
    showAdd(data) {
      this.formValidateData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_RESOURCE_MODAL].showModal();
    },

     showEdit(data) {
      this.formValidateData = this.deepClone(data) || {};
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_RESOURCE_MODAL].showModal();
    },

    showDel(data) {
      this.$confirm("确认要删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        this.doDel(data.id);
      });
    },

    doDel(id) {
      delPermission({ id }).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.validateCurrentDataLen([id], this.dataSource, this);
        this.getList();
      });
    },

   

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      
      fetchPermissionTree(this.query).then((res) => {
        this.dataSource = res.data.data || [];
        this.resources = [
        {
          name: "根节点",
          id: 0,
        },...this.dataSource
      ];
        this.total = res.data.data.total;
      });
    },

    operationSource(params) {
      let result;
      let msg = "";
      if (!this.validatenull(params.id)) {
        msg = "编辑成功";
        result = updatePermission;
        console.log(JSON.stringify(params))
        params = {
          pid: params.pid,
          id:params.id,
          url:params.url,
          name:params.name,
          permission:params.permission,
          parentIds:params.parentIds,
          type: params.type
        }
        console.log(JSON.stringify(params))
      } else {
        msg = "添加成功";
        result = addPermission;
        params.icons = "";
      }

      result(params).then((res) => {
        this.$message({
          message: msg,
          type: "success",
        });
        this.$refs[this.MODAL_KEY.EDIT_RESOURCE_MODAL].closeModal();
       this.getList();
      });
    },
  },

  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
</style>
